import React, { useEffect } from "react"
import Helmet from "react-helmet"

import LivingWithSMVideoPlaceholder from "../../images/LivingWithSMVideo-placeholder.jpg"

const LivingWithSMVideo = () => {
  useEffect(() => {
    const iframe = document.querySelector("#living-with-sm-video iframe")
    const placeholder = document.querySelector(
      "#living-with-sm-video .video-placeholder"
    )

    placeholder.addEventListener("click", () => {
      placeholder.style.display = "none"
      iframe.contentWindow.postMessage(
        { method: "play" },
        "https://player.vimeo.com"
      )
    })
  }, [])

  return (
    <>
      <Helmet>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </Helmet>
      <div
        id="living-with-sm-video"
        className="living-with-sm-video"
        style={{ padding: "56.25% 0 0 0", position: "relative" }}
      >
        <iframe
          src="https://player.vimeo.com/video/966000857?h=156e027d3c&badge=0&autoplay=0&loop=0&autopause=0&player_id=0&app_id=58479&controls=1"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
          }}
          title="FALLING CARDS V2 RENDER"
        ></iframe>
        <img
          src={LivingWithSMVideoPlaceholder}
          alt="Indolent systemic mastocytosis (ISM) video thumbnail"
          className="video-placeholder"
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            zIndex: "1",
            cursor: "pointer",
          }}
        />
      </div>
    </>
  )
}

export default LivingWithSMVideo

{
  /* <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/966000857?h=156e027d3c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="24FCB1002_GPO_Burden_of+Disease_Video"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script> */
}
