import * as React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
//import PageHero from "../components/template-partials/page-hero"
import ReferenceBlock from "../../components/template-partials/reference-block"
import QuoteDiv from "../../components/template-partials/quote-div"

import FooterCrown from "../../components/template-partials/footer-crown"
import FlipCard from "../../components/template-partials/flip-card"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import linkOutIcon from "../../images/link_out_bold.svg"

//import { AnchorLink } from "gatsby-plugin-anchor-links"

//images
// import assays from "../../images/dyk-ls-assays.png"
// import assaysMobile from "../../images/dyk-ls-assays-mbl.png"
// import assaysBg from "../../images/dyk-assays-bg.png"

import LivingWithSMVideo from "../../components/template-partials/living-with-sm-video"

import blueCard1 from "../../images/cards/psycological_card.png"
import blueCard2 from "../../images/cards/social_card.png"
import blueCard3 from "../../images/cards/emotional_card.png"
import blueCard4 from "../../images/cards/cognitive_card.png"
import blueCard5 from "../../images/cards/environmental_card.png"

import blueCardFlipped1 from "../../images/cards/psycological_card_flipped.png"
import blueCardFlipped2 from "../../images/cards/social_card_flipped.png"
import blueCardFlipped3 from "../../images/cards/emotional_card_flipped.png"
import blueCardFlipped4 from "../../images/cards/cognitive_card_flipped.png"
import blueCardFlipped5 from "../../images/cards/environmental_card_flipped.png"

import ism_symptoms_infographic from "../../images/ism_symptoms_infographic.png"
import chart_survey from "../../images/chart_survey.png"
import question_icon from "../../images/question_icon.svg"

const LivingWithSMPage = () => (
  <Layout route="recognizing-sm/living-with-sm">
    <Seo
      page="test-kit"
      title="Living with SM"
      description="Uncover the burden of living with SM and discover the impacts on patients' daily lives, including
those with the indolent subtype."
    />

    <div className="row lg_mt-3 mt-1 align-center medium-align-left">
      <div className="columns small-12 large-12">
        <h1 className="color--purple text--watch-quinn">
          <span className="powder">What you may not see</span> is that the
          symptom burden of systemic mastocytosis (SM), including the indolent
          subtype, can significantly disrupt patients’ lives<sup>1,2</sup>
        </h1>
        <p className="h2 bold mt-3 purple">
          Indolent systemic mastocytosis (ISM) represents ~90% of SM cases,
          <sup>3</sup> and the symptoms of ISM can impact patients’{" "}
          <span className="powder">work</span>,{" "}
          <span className="powder">social lives</span>, and
          <span className="powder"> overall well-being</span>
          <sup>1,2</sup>
        </p>
      </div>
    </div>
    <div className="quote_div_mt mobile-padding">
      <QuoteDiv
        text1="“I tend to isolate when I have more symptoms. I stay in a lot, and it can feel depressing.”"
        text2="Teri, a patient describing living with ISM"
        bgColor="purple"
      />
    </div>
    <div id="video"></div>
    <div className="row lg_mt-2 mt-2 lg_mb-2 mb-1 align-center medium-align-left">
      <div className="columns small-12 text-center mobile-text-left">
        <p className="color--purple lead video-header">
          <strong>
            Watch the video to learn from Kristine and Sarah, 2 patients living
            with SM, about how ISM impacts their everyday lives
          </strong>
        </p>
      </div>
    </div>

    {/* video */}
    <div className="row lg_mt-2 lg_mb-2 align-center medium-align-left">
      <div className="columns large-7 small-12 text-center centered_div">
        <LivingWithSMVideo />
      </div>
    </div>

    <div className="row  lg_mt-0 mt-1 align-center medium-align-left">
      <div className="columns large-7 small-12 text-center centered_div">
        <ul className="hanging-ref color--purple footnote_ul">
          <li>
            These individuals receive compensation from Blueprint Medicines for
            sharing their experience living with systemic mastocytosis through
            the Blueprint Medicines SM Ambassador Program.
          </li>
        </ul>
      </div>
    </div>

    <div className="row lg_mt-3 mt-2 align-center medium-align-left">
      <div className="columns  medium-expand">
        <p className="h2 bold mt-2 purple">
          Patients with ISM reported{" "}
          <span className="powder">reducing work hours</span> and{" "}
          <span className="powder">avoiding leaving their homes</span> because
          of their symptoms<sup>1,2</sup>*
        </p>

        <p className="color--purple h5 lg_mt-2 mt-2 purple bodyP">
          To help understand the daily impact of symptoms on patients living
          with SM, Blueprint Medicines sponsored and developed a patient survey
          in collaboration with SM physician experts and SM patient advocates.
          37 patients with ISM were surveyed, and among the 32 patients with
          moderate to severe ISM, the symptoms of ISM resulted in<sup>2</sup>*:
        </p>
      </div>
    </div>

    <div className="row lg_mt-2 mt-2 align-center medium-align-left">
      <div className="columns  medium-expand">
        <div className="percent_col three_col">
          <div className="col_inner borderRight">
            <p className="percent_header text--watch-quinn">~56%</p>
            <p className="purple h3">of patients reported</p>
            <p className="font22">
              <strong>
                <span className="powder">reducing their work hours</span>
              </strong>
            </p>
          </div>

          <div className="col_inner center_col">
            <p className="percent_header text--watch-quinn">28%</p>
            <p className="purple h3">of patients reported going on</p>
            <p className="font22">
              <strong>
                <span className="powder">medical disability</span>
              </strong>
            </p>
          </div>

          <div className="col_inner borderLeft">
            <p className="percent_header text--watch-quinn">72%</p>
            <p className="purple h3">of patients reported</p>
            <p className="font22">
              <strong>
                <span className="powder">
                  avoiding leaving
                  <br className="show-for-large" /> their homes
                </span>
              </strong>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="row lg_mt-3 mt-2 align-center medium-align-left">
      <div className="columns medium-expand">
        <p className="h2 bold mt-2 purple">
          A majority of patients with ISM have also reported feelings of
          <span className="powder"> depression</span>,{" "}
          <span className="powder">fatigue</span>, and{" "}
          <span className="powder">forgetfulness</span>
          <sup>4†</sup>
        </p>

        <p className="color--purple h5 lg_mt-2 mt-2 purple bodyP">
          2 quality-of-life (QOL) surveys were conducted on a group of 164
          patients living with ISM. These surveys examined various aspects of
          patients' lives, including the psychological, social, emotional,
          environmental, and cognitive impacts associated with ISM.<sup>4†</sup>
        </p>
        <p className="color--purple bodyP mt-3 text-center">
          <strong>
            Select each of the cards below to learn more about the aspects of
            patients’ lives affected by ISM
          </strong>
        </p>
      </div>
    </div>

    <div className="row gray_gradient_row">
      <div className="row lg_mt-3 align-center">
        <div className="columns small-12 large-offset-0 large-3">
          <FlipCard>
            <div className="front">
              <img
                alt="Psychological SM symptoms"
                src={blueCard1}
                width="100%"
                height="auto"
              />
            </div>
            <div className="back back--secondary backImg">
              <img
                alt="Psychological SM symptoms"
                src={blueCardFlipped1}
                width="100%"
                height="auto"
              />
            </div>
          </FlipCard>
        </div>

        <div className="columns small-12 large-offset-0 large-3 ">
          <FlipCard>
            <div className="front">
              <img
                alt="Social concerns"
                src={blueCard2}
                width="100%"
                height="auto"
              />
            </div>
            <div className="back back--secondary backImg">
              <img
                alt="Social concerns"
                src={blueCardFlipped2}
                width="100%"
                height="auto"
              />
            </div>
          </FlipCard>
        </div>
        <div className="columns small-12 large-offset-0 large-3">
          <FlipCard>
            <div className="front">
              <img
                alt="Emotional concerns"
                src={blueCard3}
                width="100%"
                height="auto"
              />
            </div>
            <div className="back back--secondary backImg">
              <img
                alt="Emotional concerns"
                src={blueCardFlipped3}
                width="100%"
                height="auto"
              />
            </div>
          </FlipCard>
        </div>
      </div>

      <div className="row align-center lg_mt-2 ">
        <div className="columns small-12 large-offset-0 large-3">
          <FlipCard>
            <div className="front">
              <img
                alt="Cognitive SM symptoms"
                src={blueCard4}
                width="100%"
                height="auto"
              />
            </div>
            <div className="back back--secondary backImg">
              <img
                alt="Cognitive SM symptoms"
                src={blueCardFlipped4}
                width="100%"
                height="auto"
              />
            </div>
          </FlipCard>
        </div>
        <div className="columns small-12 large-offset-0 large-3 ">
          <FlipCard>
            <div className="front">
              <img
                alt="Environmental concerns"
                src={blueCard5}
                width="100%"
                height="auto"
              />
            </div>
            <div className="back back--secondary backImg">
              <img
                alt="Environmental concerns"
                src={blueCardFlipped5}
                width="100%"
                height="auto"
              />
            </div>
          </FlipCard>
        </div>
        <div
          className="columns small-12 large-12 lg_mt-1 mt-2 mb-0"
          style={{ visibility: "hidden" }}
        >
          <p
            className="mb-0 mt-0 color--purple"
            style={{ lineHeight: `1` }}
          ></p>
          <p className="mb-0 mt-0 color--purple">
            Symptoms may vary from person to person.
          </p>
        </div>
      </div>
    </div>

    <div className="row lg_mt-2 mt-2 align-center medium-align-left">
      <div className="columns medium-expand">
        <p className="h2 bold mt-2 purple">
          Despite taking{" "}
          <span className="powder">multiple symptom-directed therapies</span>,
          patients still have significant symptom burden<sup>2</sup>*
        </p>
      </div>
    </div>

    <div className="mobile-padding less-space">
      <QuoteDiv
        text1="“I’ve taken many different medications, and found that my symptoms just weren’t resolved.”"
        text2="Teri, a patient describing living with ISM"
        bgColor="pink"
      />
    </div>

    <div className="row lg_mt-2 mt-2 align-center medium-align-left">
      <div className="columns medium-expand">
        <p className="color--purple bodyP purple lg_mt-2 mt-2 text-center video-header">
          <strong>
            In the Blueprint Medicines–sponsored patient-reported survey, of
            patients with ISM (n=37)<sup>2</sup>*:
          </strong>
        </p>

        <div className="info_graphic_image">
          <img
            alt="Indolent systemic mastocytosis (ISM) symptoms"
            className="centered_div "
            src={ism_symptoms_infographic}
            width="100%"
            height="auto"
          />

          <a
            href="https://www.ayvakithcp.com/ism/"
            target="_blank"
            rel="noreferrer"
            className="btn btn--mustard  lg_mt-2 mt-1 centered_div modal-link-bp"
            title="Explore a treatment option"
          >
            EXPLORE A TREATMENT OPTION FOR ISM{" "}
            <img
              alt="Suspect-SM-logo"
              className="main-header__logo dropdown_link_out_icon"
              src={linkOutIcon}
            />
          </a>
        </div>
      </div>
    </div>

    {/* <div className="row small-collapse medium-uncollapse subtype-breaker row_margin">
      <div className="columns">
        <div
          className="hero-breaker hero-breaker--progress"
          style={{ "--bg-image": `url(${assaysBg})` }}
        >
          <div className="row align-middle small-col-reverse subtype-breaker-overflow">
            <div
              className="columns hero-breaker--person hero-breaker--person--left hero-breaker--person--assays small-12 large-4"
              style={{
                "--bg-image": `url(${assays})`,
                "--bg-image-mbl": `url(${assaysMobile})`,
              }}
            >
              <span className="visually-hidden">Woman with hand on chest</span>
            </div>
            <div className="columns small-12 large-8 text-left mt-0">
              <div className="row">
                <div className="columns large-12">
                  <p className="color--purple text--watch-quinn p_56">
                    Did you know? A treatment option is available for your
                    patients with ISM
                  </p>

                  <AnchorLink
                    to="/test-for-kit-d816v/#find-a-lab"
                    className="btn btn--mustard btn--small-fw lg_mt-1 mt-1"
                    title="Find a lab"
                  >
                    EXPLORE A TREATMENT OPTION
                  </AnchorLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}

    <div className="row lg_mt-0 mt-0 align-center medium-align-left">
      <div className="columns medium-expand">
        <p className="h2 bold mt-2 purple">
          Why your patients might{" "}
          <span className="powder">not be telling you the whole story</span>
        </p>
      </div>
    </div>

    <div className="quote_div_mt mobile-padding">
      <QuoteDiv
        text1="“It’s all about the relationship and trusting each other—the doctor and patient—knowing that you are a team; otherwise, the patient may hold back.”"
        text2="Kristine, a patient living with ISM"
        bgColor="purple"
      />
    </div>

    <div className="row align-center medium-align-left">
      <div className="columns medium-expand">
        <p className="color--purple lg_mt-2 mt-2 purple bodyP mt-3">
          Patients may not share how they are truly feeling or may have adjusted
          to a life burdened by SM symptoms.{" "}
          <strong>
            Consider asking your patients these additional questions to get the
            full story
          </strong>
          <sup>4,5</sup>:
        </p>
      </div>
    </div>

    <div className="row lg_mt-1 mt-0 align-center medium-align-left">
      <div className="columns  medium-expand">
        <div className="percent_col four_col centered_div ism_col">
          <div className="col_inner borderRight">
            <img
              alt=""
              src={question_icon}
              width="43px"
              height="auto"
              className="question_icon"
            />
            <p className="font20 text--watch-quinn tightened-line-height mt-2">
              What activities have you reduced or stopped doing because of ISM?
            </p>
          </div>

          <div className="col_inner borderRight">
            <img
              alt=""
              src={question_icon}
              width="43px"
              height="auto"
              className="question_icon"
            />
            <p className="font20 text--watch-quinn tightened-line-height mt-2">
              What adjustments have you made in your daily life to manage ISM?
            </p>
          </div>

          <div className="col_inner borderRight">
            <img
              alt=""
              src={question_icon}
              width="43px"
              height="auto"
              className="question_icon"
            />
            <p className="font20 text--watch-quinn tightened-line-height mt-2">
              Does ISM impact how you perform daily activities and work?
            </p>
          </div>

          <div className="col_inner">
            <img
              alt=""
              src={question_icon}
              width="43px"
              height="auto"
              className="question_icon"
            />
            <p className="font20 text--watch-quinn tightened-line-height mt-2">
              How is ISM affecting the lives of you and your family?
            </p>
          </div>
        </div>
      </div>
    </div>

    {/* <div className="row lg_mt-2  align-center medium-align-left">
      <div className="columns  medium-expand">
        <div className="percent_col two_col no-border centered_div ism_col">
          <div className="col_inner borderRight">
            <p className="font24 text--watch-quinn">
              Does ISM impact how you perform daily activities and&nbsp;work?
            </p>
          </div>

          <div className="col_inner ">
            <p className="font24 text--watch-quinn">
              How is ISM affecting the lives of you and your family?
            </p>
          </div>
        </div>
      </div>
    </div> */}

    <div className="row lg_mt-2 align-center medium-align-left">
      <div className="columns medium-expand">
        <p className="h2 bold mt-2 s-mt-1 purple">
          Some patients reported being{" "}
          <span className="powder">
            hesitant about discussing their symptoms
          </span>{" "}
          and only reporting symptoms if they are in significant distress
          <sup>6</sup>
        </p>

        <p className="color--purple h5 lg_mt-3 mt-3 text-center purple video-header">
          <strong>
            In the Blueprint Medicines-sponsored patient-reported survey,{" "}
            <br className="show-for-large" /> among the subset of patients with
            ISM (n=37)*:
          </strong>
        </p>
      </div>
    </div>

    <div className="row lg_mt-2 mt-2 align-center medium-align-left">
      <div className="columns medium-expand">
        <div className="chart_div centered_div">
          <img
            alt="Blueprint Medicine-sponsored patient-reported survey of patients with ISM"
            src={chart_survey}
            width="100%"
            height="auto"
          />
          <div className="chart_bullet">
            <div className="bullet_item">
              <div className="circle gray"></div>
              <p className="font22 weight-normal">
                Discuss symptoms with their HCP only when they feel really bad
              </p>
            </div>
            <div className="bullet_item">
              <div className="circle purple"></div>
              <p className="font22 weight-normal">
                Feel they are burdening their HCP when discussing how they are
                feeling
              </p>
            </div>
          </div>
        </div>
        <br />

        <ul className="hanging-ref color--purple footnote_ul">
          <li>
            <span className="ref">*</span>
            In the Blueprint Medicines-sponsored TouchStone SM Patient Survey,
            56 US adults with a self-reported SM diagnosis completed a 100-item
            survey. An unpublished analysis of a subset of those patients with
            ISM (n=37), including 32 with moderate to severe ISM symptoms
            (defined as an ISM-SAF TSS ≥28), was conducted.
          </li>
          <li>
            <sup className="ref">†</sup>
            Data from a Dutch validation study of 2 patient-reported outcome
            measures of QOL and symptom burden in 164 patients with ISM.
            Patients completed a 49-item mastocytosis QOL questionnaire (MQLQ)
            asking patients to rate the burden of ISM symptoms on their daily
            life and the mastocytosis symptom assessment form (MSAF) asking
            patients to rate the severity of their ISM symptoms and the
            influence of fatigue on specific categories (eg, mood/temper,
            chores, happiness) over the past 24 hours.<sup>4</sup>
          </li>
        </ul>
      </div>
    </div>

    <div className="row lg_mt-7 mt-7 lg_mb-7 mb-7 align-center medium-align-left">
      <div className="columns small-12 medium-expand text-right">
        <p className="h4 color--purple mobileBodyP">
          The symptoms of SM can be mistaken for other
          <br className="show-for-large" /> conditions<sup>7</sup>
        </p>
        <Link
          className="btn btn--outline btn--icon uppercase mt-1"
          to="/recognizing-sm/related-conditions/"
        >
          SEE HOW <FontAwesomeIcon className="" icon={faAngleRight} />
        </Link>
      </div>
    </div>

    <FooterCrown type="home" />

    <ReferenceBlock>
      <ol className="color--purple">
        <li>
          Pardanani A. <em>Am J Hematol</em>. 2023;98(7):1097-1116.
        </li>
        <li>
          Data on file. Blueprint Medicines Corporation, Cambridge, MA. 2023.
        </li>
        <li>
          Ungerstedt J et al. <em>Cancers</em>. 2022;14(16):3942.
        </li>
        <li>
          van Anrooij B et al. <em>Allergy</em>. 2016;71(11):1585-1593.
        </li>
        <li>
          Mesa RA et al. <em>Cancer</em>. 2022;128(20):3691-3699.
        </li>
        <li>
          Data on file. Blueprint Medicines Corporation, Cambridge, MA. 2024.
        </li>
        <li>
          Theoharides TC et al. <em>N Engl J Med</em>. 2015;373(2):163-172.
        </li>
      </ol>
    </ReferenceBlock>
  </Layout>
)

export default LivingWithSMPage
